import React from 'react';
import BandcampLogo from '../../assets/img/bandcamp-logo.png'
import { ImgContainer } from './BandCampBadgeStyles';

const BandcampBadge = ({ bandcampUrl} ) => (
  <ImgContainer>
    <a href={bandcampUrl}>
      <img src={BandcampLogo} alt=""/>
    </a>
  </ImgContainer>
);

export default BandcampBadge;
