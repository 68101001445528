import styled from 'styled-components';

export const AlbumHeader = styled.div`
  background-color: ${({ theme }) => theme.colors.sapphireBlue};

  h1 {
    color: ${({ theme }) => theme.colors.white};
    padding-bottom: 48px;

    @media (min-width: 768px) {
      padding-bottom: 48px;
    }
  }
`;

export const AlbumBody = styled.section`
  background-color: ${({ theme }) => theme.colors.sapphireBlue};
  color: ${({ theme }) => theme.colors.sapphireBlue};
  padding-bottom: 32px;

  .iframeContainer {
    display: flex;
    justify-content: center;
  }

  @media (min-width: 768px) {
    color: ${({ theme }) => theme.colors.white};

    .iframeContainer {
      justify-content: flex-start;
    }
  }

  .iframeContainer {

    iframe {
      width: 100% !important;
      max-width: 350px !important;
    }
  }

  p {
    margin-bottom: 16px;
  }

  .badges {
    margin: 0 auto;
    margin-top: 8px;
    max-width: 350px;
    display: flex;
    justify-content: space-between;

    @media (min-width: 768px) {
      margin: 0;
      margin-top: 32px;
    }
  }
`;

export const AlbumDesc = styled.section`
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.sapphireBlue};
  padding: 32px 0;
  
  p {
    margin-bottom: 16px;
  }
`;

export const ReviewsSection = styled.section`
  padding: 32px 0;
  background-color: ${({ theme }) => theme.colors.sapphireBlue};
  color: ${({ theme }) => theme.colors.white};

  @media (min-width: 768px) {
    padding: 48px 0;  
    background-color: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.sapphireBlue};  
  }

  p {
    font-weight: 400;
    line-height: 1.3;
  }

  .container {
    @media (min-width: 992px) {
      max-width: 60%;  
    }
  }

  .reviews-container {
    padding: 25px 0;

    .author {
      margin-top: 32px;
      text-align: right;
      font-style: italic;
    }
  }

  .testimonials > div:first-of-type {

    .reviews-container {
      padding-top: 0;
    }

    &::after {
      content: '';
      display: block;
      margin: 0 auto;
      width: 8%;
      border-bottom: 1px solid white;
    }
  }
`;

export const CreditsSection = styled.section`
  padding: 32px 0;
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.sapphireBlue};

  @media (min-width: 768px) {
    padding: 48px 0;  
    background-color: ${({ theme }) => theme.colors.sapphireBlue};
    color: ${({ theme }) => theme.colors.white};  
  }

  p {
    font-weight: 400;
    line-height: 1.3;
    white-space: pre-wrap;
    padding-bottom: 32px;

    @media (min-width: 768px) {
      padding-bottom: 64px;
    }
  }

  .container {
    @media (min-width: 992px) {
      max-width: 60%;  
    }
  }
`;

export const StyledH2 = styled.h2`
  margin: 24px auto;
  text-align: center;

  @media (min-width: 768px) {
    margin: 32px auto;
    margin-bottom: 48px;
  }
`;
