import styled from 'styled-components';

export const ImgContainer = styled.div`
  display: inline-block;
  background-color: ${({ theme }) => theme.colors.inkyBlack};
  vertical-align: top;
  padding: 8px;
  padding-bottom: 0;
  border-radius: 8px;

  img {
    width: 123px;
    height: 28px;
    object-fit: contain;
  }
`;
