import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import parse from 'html-react-parser'

import { useMediaQuery } from 'react-responsive';

import { Container, Row, Col } from 'react-bootstrap';
import BreadCrumbs from '../../components/BreadCrumbs/BreadCrumbs';
import { AlbumHeader, AlbumBody, AlbumDesc, ReviewsSection, StyledH2, CreditsSection } from './albumTemplateStyles';

import BandcampBadge from '../../components/BandcampBadge/BandCampBadge'
import iTunesBadge from '../../assets/img/iTunes.png'

const AlbumPage = ( { data } ) => {
  const { albumTitle, reviews, iTunesUrl, bandcampUrl } = data.contentfulAlbum;
  const { html: bandcampWidgetShortcode } = data.contentfulAlbum.bandcampWidgetShortcode.childMarkdownRemark;
  const { html: albumDescription } = data.contentfulAlbum.albumDescription.childMarkdownRemark;
  const { html: albumCredits } = data.contentfulAlbum.credits.childMarkdownRemark;

  const isTabletOrMobile = useMediaQuery({ maxWidth: 767 });

  const renderAlbumBodySection = () => {
    if (isTabletOrMobile) {
      return (
        <>
          <AlbumBody>
            <Container>
              <div className="iframeContainer">
                {parse(bandcampWidgetShortcode)}
              </div>
              <div className="badges">
                <a href={iTunesUrl}>
                  <img src={iTunesBadge} alt="Buy it on iTunes" />
                </a>
                <BandcampBadge bandcampUrl={bandcampUrl} alt="Get it on Bandcamp" />
              </div>
            </Container>
          </AlbumBody>
          <AlbumDesc>
            <Container>
              {parse(albumDescription)}
            </Container>
          </AlbumDesc>
        </>
      )
    } else {
      return (
        <AlbumBody>
          <Container>
            <Row>
              <Col md={6}>
                <div className="iframeContainer">
                  {parse(bandcampWidgetShortcode)}
                </div>
                <div className="badges">
                  <a href={iTunesUrl}>
                    <img src={iTunesBadge} alt="Buy it on iTunes" />
                  </a>
                  <BandcampBadge bandcampUrl={bandcampUrl} alt="Get it on Bandcamp" />
                </div>
              </Col>
              <Col md={{ span: 6}}>
                {parse(albumDescription)}
              </Col>
            </Row>
          </Container>
        </AlbumBody>
      )
    }
  }
  
  const renderReviews = () => {
    return reviews.map( review => {
      return (
        <div key={review.id} className="reviews-container">
          {parse(review.quote.childMarkdownRemark.html)}
          <p className="author">{review.author}</p>
        </div>
      )
    } )
  }

  const [hasMounted, setHasMounted] = useState(false);

  useEffect(() => {
    setHasMounted(true);
  }, []);

  if (!hasMounted) {
    return null;
  }
  
  return (
    <Layout>
      <SEO title="Album Page" />
      <AlbumHeader>
        <Container>
          <BreadCrumbs archiveLink={'Albums'} singlePageTitle={albumTitle} color="white"/>
          <h1>{albumTitle}</h1>
        </Container>
      </AlbumHeader>
      {renderAlbumBodySection()}
      <ReviewsSection>
        <Container>
          <StyledH2>Reviews</StyledH2>
          {renderReviews()}
        </Container>
      </ReviewsSection>
      <CreditsSection>
        <Container>
          <StyledH2>Credits</StyledH2>
          {parse(albumCredits)}
          <p>&copy; all rights reserved</p>
        </Container>
      </CreditsSection>
    </Layout>
  );
}

export default AlbumPage;

export const pageQuery = graphql`
  query($slug: String!) {
    contentfulAlbum(slug: {eq: $slug}) {
      albumCover {
        fluid {
          aspectRatio
          base64
          sizes
          src
          srcSet
        }
      }
      albumDescription {
        childMarkdownRemark {
          html
        }
      }
      bandcampWidgetShortcode {
        childMarkdownRemark {
          html
        }
      }
      iTunesUrl
      bandcampUrl
      albumTitle
      reviews {
        quote {
          childMarkdownRemark {
            html
          }
        }
        author
        id
      }
      credits {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`
